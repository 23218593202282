import React from "react";

function Blog() {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col gap-2 ">
        <h1 className="font-bold text-3xl text-left text-black">Afia's Blog</h1>
        <p className="text-base font-normal text-gray-500 ">
          Welcome to where my thoughts are safe!
        </p>
      </div>
      <div className="flex flex-col justify-center items-center w-full h-full">
        <img src="/comingsoon.png" alt="" className="w-auto mt-20 h-auto" />
      </div>
    </div>
  );
}

export default Blog;
