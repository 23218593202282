import React from "react";
import PropTypes from "prop-types";

function Casecard(props) {
  return (
    <div>
      <img src={props.imgurl} alt="" />
      <div className="flex flex-row justify-between px-4 items-center">
        <div className="flex flex-col ">
          <h4 className="text-gray-900 text-xl font-semibold  ">
            {props.title}
          </h4>
          <p className=" text-gray-400 text-base  ">{props.subtxt}</p>
        </div>
        <p className=" text-lg text-[#3C66FF]  text-right">{props.year}</p>
      </div>
    </div>
  );
}
Casecard.propTypes = {
  imgurl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtxt: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
};
Casecard.defaultProps = {
  imgurl: "/comingsoon.png",
  title: "Project",
  subtxt: "Project details",
  year: 2024,
};

export default Casecard;
