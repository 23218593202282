import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Layout from "./components/Layout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ErrorPage from "./error-page";
import Blog from "./blogs/Blog";
import Lab from "./lab/Lab";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<App />} />{" "}
          <Route exact path="/blogs" element={<Blog />} />{" "}
          <Route exact path="/laboratory" element={<Lab />} />
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
      </Layout>
    </Router>
  </React.StrictMode>
);
