import React from "react";
import Casecard from "../components/Casecard";
import { PiGithubLogoFill } from "react-icons/pi";
import { FaLinkedinIn } from "react-icons/fa";

function Lab() {
  return (
    <div className="flex  flex-col gap-14  ">
      <div className="flex flex-col gap-5 justify-between">
        <div className=" flex flex-col ">
          <h1 className="font-bold text-3xl text-black">
            Welcome To My Laboratory
          </h1>
          <p className="text-base font-normal text-gray-500">
            Everything you see is more than just a collection of tech stacks and
            languages.
          </p>
        </div>
        <div className="flex flex-row justify-start items-center gap-3">
          <button className="outline rounded-sm outline-gray-700  outline-1 h-8 w-8">
            <PiGithubLogoFill className=" w-6 h-6 m-auto" />
          </button>
          <button className="outline  rounded-sm outline-gray-700 outline-1 h-8 w-8">
            <FaLinkedinIn className=" w-6 h-6 m-auto" />
          </button>
        </div>
      </div>
      <div>
        <h2 className="text-2xl mb-8 font-medium text-black">
          Selected Case Studies:
        </h2>
        <div className="flex flex-col gap-14 items-center max-w-2xl ">
          <Casecard
            title="Delve AI"
            imgurl="/delve.png"
            subtxt="AI - powered platform simplifying documents"
            year={2024}
          />
          <Casecard
            title="Pamvaj"
            imgurl="/pamvaj.png"
            subtxt="Connecting users with local services"
            year={2024}
          />
          <Casecard
            title="Frelapay"
            imgurl="/frelapay.png"
            subtxt="Payment solution for Freelancers"
            year={2024}
          />
        </div>
      </div>
    </div>
  );
}

export default Lab;
