import React from "react";
import { Link, useLocation } from "react-router-dom";
import { RiCircleFill, RiNewspaperLine } from "react-icons/ri";

function Layout({ children }) {
  let location = useLocation().pathname;
  return (
    <div>
      <header className="px-6 pt-6  flex flex-row justify-between w-full sm:justify-around sm:gap-40">
        <button className="outline text-base text-green-600 outline-1 outline-gray-200 rounded-full flex flex-row min-w-fit items-center gap-2 px-3 py-2">
          <RiCircleFill className="stroke-green-600" /> Currently available
        </button>
        <button className="outline text-base font-medium text-black outline-1 outline-gray-200 rounded-full flex flex-row min-w-fit items-center gap-2 px-3 py-2">
          <RiNewspaperLine className=" stroke-black" /> Resume
        </button>
      </header>
      <div className=" flex flex-col sm:flex-row pt-14 ">
        <aside className="flex flex-row sm:flex-col px-6 sm:px-20 gap-2  sm:gap-4">
          <div className="flex flex-row sm:flex-col gap-4 h-fit justify-start items-start  ">
            <Link
              className={`inline font-medium text-base ${
                location === "/" ? "text-black" : "text-gray-500"
              } w-fit`}
              to="/"
            >
              Home
            </Link>
            <Link
              className={`inline font-medium text-base ${
                location === "/blogs" ? "text-black" : "text-gray-500"
              } w-fit`}
              to="/blogs"
            >
              Blogs
            </Link>
            <Link
              className={`inline font-medium text-base ${
                location === "/laboratory" ? "text-black" : "text-gray-500"
              }  w-fit`}
              to="/laboratory"
            >
              Laboratory
            </Link>
          </div>
          <hr className="  " />
          <div className="flex flex-row sm:flex-col gap-3 justify-start items-start  ">
            <a
              className="inline font-medium text-base text-gray-900  w-fit"
              href="http://"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
            <a
              className="inline font-medium text-base text-gray-900  w-fit"
              href="mailto:"
            >
              Email
            </a>
          </div>
        </aside>
        <main className=" p-6 ">{children}</main>
      </div>
    </div>
  );
}

export default Layout;
